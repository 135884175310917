import { BsFillJournalBookmarkFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { AppNavBar } from '../../../../components/navBar';
import { useAuth } from '../../../../contexts/auth';

export const CallReport = () => {
    const { patient } = useAuth();
    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4">
                <div className="d-flex flex-column align-items-center mb-5">
                    <div className="icon big">
                        <BsFillJournalBookmarkFill size={'5rem'} />
                    </div>

                    <div className="py-4 text-center">
                        <h4 className="f28 bold pb-4">Relato sobre Atividade</h4>

                        <p className="f20 text-center">
                            {patient?.nome}, agora que você terminou esta atividade, deseja nos informar como <br />
                            se sente em relação a esta atividade recém completa?
                        </p>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <Link className="btn btn-outline mb-4" to={'/report'}>
                            <span className="f24">Sim, desejo fazer meu relato</span>
                        </Link>

                        <Link className="btn btn-outline" to={'/dashboard'}>
                            <span className="f14">Não, desejo fazer em outro momento</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
