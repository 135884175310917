function buildFormData(formData: any, data: any, parentKey: string | null) {
    if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data === null ? "" : data;

        if (value) {
            formData.append(parentKey, value);
        }
    }
}

export const getFormData = (data: any) => {
    const formData = new FormData();
    buildFormData(formData, data, null);

    return formData;
};
