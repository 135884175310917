import { useEffect, useRef, useState } from 'react';
import { Collapse, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { FaHeart, FaUserCircle } from 'react-icons/fa';

import { TiMessages } from 'react-icons/ti';
import { CgProfile } from 'react-icons/cg';
import { GoSignOut } from 'react-icons/go';

// import { BsFillJournalBookmarkFill } from 'react-icons/bs';

import { Avatar } from '../avatar';
import { Menu } from '../../assets/icons/Menu';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { LogoZeebras } from '../../assets/icons/LogoZeebras';

export const AppNavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { Logout, setIsLoading } = useAuth();
    const navigate = useNavigate();
    const menuRef = useRef<HTMLDivElement>(null);


    const toggleNavbar = () =>
        setTimeout(() => {
            setIsMenuOpen(!isMenuOpen);
        }, 100);

    const handleClickOutside = (event: MouseEvent) => {
        if (!menuRef.current?.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('click', handleClickOutside);
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isMenuOpen]);

    return (
        <div id="header">
            <NavbarBrand className="logo">
                <LogoZeebras />
            </NavbarBrand>

            <div className="header-right">
                <Avatar />

                {
                    <NavbarToggler onClick={toggleNavbar} className="">
                        <Menu />
                    </NavbarToggler>
                }
            </div>

            <div ref={menuRef}>
                <Collapse
                    id="sidebar"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                    isOpen={isMenuOpen}
                    navbar
                >
                    <Nav navbar>
                        <NavItem>
                            <NavLink
                                to={'/home'}
                                style={({ isActive }) => {
                                    return {
                                        pointerEvents: isActive ? 'none' : 'auto',
                                    };
                                }}
                            >
                                <Menu width={'28px'} /> Ínicio
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <Link to={'/evaluations'}>
                                <FaClipboardList /> Sua Avaliação
                            </Link>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                to={'/rehab/activities'}
                                style={({ isActive }) => {
                                    return {
                                        pointerEvents: isActive ? 'none' : 'auto',
                                    };
                                }}
                                onClick={() => setIsLoading(true)}
                            >
                                <FaHeart /> Sua Reabilitação
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <Link to={'/progress'}>
                                <GiProgression /> Progresso
                            </Link>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                to={'/messenger'}
                                style={({ isActive }) => {
                                    return {
                                        pointerEvents: isActive ? 'none' : 'auto',
                                    };
                                }}
                                onClick={() => setIsLoading(true)}
                            >
                                <TiMessages /> Mensagens
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                to={'/diary'}
                                style={({ isActive }) => {
                                    return {
                                        pointerEvents: isActive ? 'none' : 'auto',
                                    };
                                }}
                            >
                                <BsFillJournalBookmarkFill /> Diário
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                to={'/professional'}
                                style={({ isActive }) => {
                                    return { pointerEvents: isActive ? 'none' : 'auto' };
                                }}
                                onClick={() => setIsLoading(true)}
                            >
                                <CgProfile /> Profissional
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                to={'/user'}
                                style={({ isActive }) => {
                                    return {
                                        pointerEvents: isActive ? 'none' : 'auto',
                                    };
                                }}
                                onClick={() => setIsLoading(true)}
                            >
                                <FaUserCircle /> Seus Dados
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={''} onClick={() => {Logout(); navigate('/');}}>
                                <GoSignOut /> Sair
                            </NavLink>
                        </NavItem>
                    </Nav>
                        <div style={{padding: '0 0 10px 15px'}}>
                            <Avatar />
                        </div>
                </Collapse>
            </div>
        </div>
    );
};
