import * as React from 'react';

export const ClipBoard = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.666 4.66699H18.7493C19.3019 4.66699 19.8318 4.88649 20.2225 5.27719C20.6132 5.66789 20.8327 6.19779 20.8327 6.75033V21.3337C20.8327 21.8862 20.6132 22.4161 20.2225 22.8068C19.8318 23.1975 19.3019 23.417 18.7493 23.417H6.24935C5.69681 23.417 5.16691 23.1975 4.77621 22.8068C4.38551 22.4161 4.16602 21.8862 4.16602 21.3337V6.75033C4.16602 6.19779 4.38551 5.66789 4.77621 5.27719C5.16691 4.88649 5.69681 4.66699 6.24935 4.66699H8.33268"
                stroke="#637194"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.6257 2.58301H9.37565C8.80035 2.58301 8.33398 3.04938 8.33398 3.62467V5.70801C8.33398 6.2833 8.80035 6.74967 9.37565 6.74967H15.6257C16.2009 6.74967 16.6673 6.2833 16.6673 5.70801V3.62467C16.6673 3.04938 16.2009 2.58301 15.6257 2.58301Z"
                stroke="#637194"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
