export const fetchImage = async (folder: string, image: string) => {
        const url = process.env.REACT_APP_API_URL!.slice(0, -4)
        const res = await Promise.resolve(fetch(`${url}${folder}/${image}`));
        if (!res.ok) return
        const imageBlob = await res.blob();
        const imageObjectURL: string = URL.createObjectURL(imageBlob);

    return imageObjectURL
};

