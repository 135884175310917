import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/theme.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
let user = true;

root.render(
    <React.StrictMode>
        <div className={user ? 'inner' : ''}>
            <div className="mask">
                <div className="container min-zeebras">
                    <App />
                </div>
            </div>
        </div>
    </React.StrictMode>
);
