import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface IParms {
    isOpenModal: boolean;
    actionTitle?: string;
    actionBody: string;
    handleCancel?: any;
    handleConfirm: any;
}
export default function ModalApp({
    isOpenModal,
    actionTitle,
    actionBody,
    handleConfirm,
    handleCancel,
}: IParms) {
    return (
        <Modal isOpen={isOpenModal}>
            <ModalHeader fontSize="lg" fontWeight="bold">
                {actionTitle}
            </ModalHeader>

            <ModalBody>{actionBody}</ModalBody>

            <ModalFooter>
                {handleCancel ? (
                    <>
                        <Button onClick={handleConfirm}>Confirmar</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </>
                ) : (
                    <Button onClick={handleConfirm}>OK</Button>
                )}
            </ModalFooter>
        </Modal>
    );
}
