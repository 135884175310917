import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import api from '../../../../services/api';
import * as yup from 'yup';
import { Button } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { SlNote, SlTrash } from 'react-icons/sl';
import { useLocation } from 'react-router-dom';
import { Rating } from '../../../../components/rating';

import { AppNavBar } from '../../../../components/navBar';
import ModalApp from '../../../../components/modal';
import { useAuth } from '../../../../contexts/auth';

interface IValues {
    titulo: string;
    relato: string;
    avaliacao: number | null;
    tipo: string;
}

interface IAlertParams {
    message: string;
    variant: string;
    active: boolean;
    action: string;
}
export const CreateOrUpdateReport = () => {
    const [alertParams, setAlertParams] = useState<IAlertParams>();
    const [rate, setRate] = useState();

    const { patient } = useAuth();
    const navigate = useNavigate();

    const { state } = useLocation();
    console.log(state);

    const initialValues: IValues = {
        titulo: 'Relato',
        relato: state?.record?.relato,
        avaliacao: state?.record?.avaliacao,
        tipo: 'relato',
    };
    const schema = yup.object().shape({
        relato: yup.string().required('Este campo é obrigatório'),
    });

    const getValue = (e: any) => {
        setRate(e);
    };

    const handleSubmit = async (values: IValues, actions: any) => {
        try {
            if (state?.record?.uuid) {
                await api
                    .put(`diario/update?uuid=${state?.record?.uuid}`, {
                        ...values,
                        avaliacao: rate,
                        atualizadoEm: new Date(),
                    })
                    .then(() => {
                        setAlertParams({
                            message: 'O seu relato foi atualizado com sucesso!',
                            variant: 'success',
                            active: true,
                            action: 'Relato Atualizado!',
                        });
                    });
            } else {
                await api
                    .post(`diario/create`, {
                        ...values,
                        avaliacao: rate,
                        atualizadoEm: new Date(),
                        pacienteId: patient?.uuid,
                    })
                    .then(() => {
                        setAlertParams({
                            message: 'O seu relato foi salvo com sucesso!',
                            variant: 'success',
                            active: true,
                            action: 'Relato Criado!',
                        });
                    });
            }
        } catch (error: any) {
            setAlertParams({
                message: 'Houve um erro ao salvar o seu registro, tente novamente mais tarde!',
                variant: 'error',
                active: true,
                action: 'Erro!',
            });
            actions.resetForm();
        }
    };

    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4">
                {state?.record?.uuid ? (
                    <div className="d-flex align-items-start justify-content-between pb-5">
                        <div className="d-flex flex-column flex-fill">
                            <h3 className="f36 pb-4">
                                <strong>{patient?.nome}</strong>, sinta-se a vontade
                                <br />
                                para atualizar o seu registro.
                            </h3>

                            <p className="f28">Me diga como você se sente em relação ao tratamento.</p>
                        </div>

                        <div className="icons-container">
                            <SlNote size={30} className="me-3" /> <SlTrash size={30} />
                        </div>
                    </div>
                ) : (
                    <div className="d-flex align-items-start justify-content-between pb-4">
                        <div className="d-flex flex-column flex-fill">
                            <h3 className="f30 pb-4">
                                <strong>{patient?.nome}</strong>, espero que esteja
                                <br />
                                confortável para começarmos.
                            </h3>

                            <p className="f24">Me diga como você se sente em relação ao tratamento.</p>
                        </div>

                        <div className="icons-container">
                            <SlNote size={30} className="me-3" /> <SlTrash size={30} />
                        </div>
                    </div>
                )}

                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(value, actions) => {
                        handleSubmit(value, actions);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form className="form-message">
                            <Field
                                id="relato"
                                name="relato"
                                as="textarea"
                                rows="6"
                                className="form-control mb-4"
                                placeholder="Digite aqui como se sente."
                            ></Field>
                            {errors.relato && touched.relato ? (
                                <span style={{ color: 'red' }}>{errors.relato}</span>
                            ) : null}

                            <div className="pb-2">
                                <p className="f24">Em uma nota de 0 a 10, o quão você está satisfeito(a)?</p>

                                <Rating getValue={getValue} rate={rate} />
                            </div>

                            <Button className="btn-outline mt-4" type="submit">
                                Salvar relato
                            </Button>
                        </Form>
                    )}
                </Formik>

                {alertParams?.active && (
                    <ModalApp
                        isOpenModal={alertParams?.active}
                        actionTitle={alertParams?.action}
                        actionBody={alertParams?.message}
                        handleConfirm={() => navigate('../records-list')}
                    />
                )}
            </div>
        </div>
    );
};
