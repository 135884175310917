import { FC, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    helperText?: string;
    htmlFor?: string;
    mask?: 'phone' | 'cpf' | 'date';
    tag?: any;
}
export const InputField: FC<InputProps> = ({ label, mask, htmlFor, helperText, ...rest }) => {
    return (
        <span className="inputContainer">
            <label htmlFor={htmlFor}>{label}</label>
            <input {...rest} id={htmlFor} />
            {helperText && <div style={{ fontSize: 'small', color: 'red' }}>{helperText}</div>}
        </span>
    );
};
