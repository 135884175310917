import { Row, Col } from 'reactstrap';
import ActivityList from '../../rehab/activityList';
import { useAuth } from '../../../../contexts/auth';
import { AppNavBar } from '../../../../components/navBar';
import { InterationTimer } from '../../../../components/interationTimer';
import { useEffect } from 'react';

export const Dashboard = () => {
    const { patient, setIsLoading } = useAuth();

    useEffect(() => {
        setIsLoading(false)
    }, [setIsLoading])

    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4">
                <Row>
                    <Col size="12">
                        <h2 className="f24 bold pb-2">Esta é a sua reabilitação</h2>

                        <p className="f14">
                            Vamos lá! Para dar início a sua reabilitação basta <br />
                            observar as orientações e realizar os passos necessários.
                        </p>
                    </Col>
                    <Col size="6">
                        <InterationTimer />
                    </Col>
                </Row>

                {/* <Row>
                    <Col size="12">
                        {rehab && <NewTimeline setTabValue={setTabValues} setCurrentSession={setCurrentSession} stages={rehab.fases_reabilitacao} />}
                    </Col>
                </Row> */}

                <Row>
                    <Col size="12">
                        <p className="f26 mt-5 pb-4">
                            <strong>{patient?.nome}</strong>, a sua reabilitação exige que você realize o(s)
                            exercício(s) exibidos logo abaixo. Vá em frente!
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col size="12">
                        <ActivityList />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
