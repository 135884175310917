/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { ClipBoard } from '../../assets/icons/ClipBoard';
// import { SendMessageIcon } from '../../assets/icons/SendMessageIcon';
import UserIcon from '../../assets/icons/User';
import api from '../../services/api';
import './styles.css';
import GenericAvatar from '../GenericAvatar';
import { useAuth } from '../../contexts/auth';
import { IDoctorData } from '../../interface';

interface IMessage {
    from: string;
    mensagem: string;
    titulo: string;
}

interface IMessages extends Array<IMessage> {}

interface IChat {
    uuid: string;
    paciente: {};
    novaMensagem: boolean;
    mensagens: IMessages;
    medico: IDoctorData;
}

const Messenger: React.FC = () => {
    const [chat, setChat] = useState<IChat>();
    const [doctorData, setDoctorData] = useState<IDoctorData>();
    const [messages, setMessages] = useState<IMessages>();
    const [newMessage, setNewMessage] = useState('');
    const chatBoxRef = useRef<HTMLLIElement>(null);
    const { rehab, patient } = useAuth();

    async function getDoctorData() {
        const { data } = await api.get(`userclient/search?search=${rehab?.medico.uuid}`);
        setDoctorData(data.usuarios_cliente[0]);
    }

    async function getChat() {
        try {
            const { data } = await api.get(`conversa/searchpaciente?pacienteId=${patient?.uuid}`);
            setChat(data.conversa[0]);
            setMessages(data.conversa[0].mensagens);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!newMessage.length) return;
        setNewMessage('');

        try {
            if (!chat) {
                const payload = {
                    pacienteId: patient?.uuid,
                    medicoId: rehab?.medico.uuid,
                    mensagens: {
                        data: [
                            {
                                from: 'paciente',
                                titulo: 'Painel Paciente',
                                mensagem: newMessage,
                            },
                        ],
                    },
                };

                await api.post(`conversa/create`, payload).then(async () => await getChat());
            } else {
                const payload = {
                    conversaId: chat?.uuid,
                    from: 'paciente',
                    titulo: 'test chat',
                    mensagem: newMessage,
                };
                await api.post(`mensagem/create`, payload).then(async () => await getChat());
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePressEnter = (e: React.KeyboardEvent) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') handleSubmit(e);
    };

    const changeTitle = (gender: string | undefined) => {
        switch (gender) {
            case 'Feminino':
                return 'Dra.';
            case 'Masculino':
                return 'Dr.';
            default:
                return 'Dr.';
        }
    };

    useEffect(() => {
        getChat();
    }, []);

    useEffect(() => {
        getDoctorData();
    }, []);

    // define a posição inicial do chat-box a partir do último elemento
    useEffect(() => {
        if (messages) {
            (chatBoxRef.current as unknown as HTMLElement).scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [messages]);

    return (
        <div className="chat-body">
            <div className="chat-header">
                <span>
                    {changeTitle(doctorData?.sexo)} <strong>{`${doctorData?.nome} ${doctorData?.sobrenome ?? ''}`}</strong>
                </span>
                <div>
                    <ClipBoard /> <UserIcon />
                </div>
            </div>

            <ul className="chat-box">
                {messages?.map((message, i) => (
                    <li key={i} ref={messages.length - 1 === i ? chatBoxRef : null}>
                        {message?.from === 'paciente' ? (
                            <></>
                            // <div className="sent-message">
                            //     <GenericAvatar src={patient?.foto!} size={5} />
                            //     <div className="message-container">{message?.mensagem}</div>
                            // </div>
                        ) : (
                            <div className="received-message">
                                <GenericAvatar folder="usuariosCliente" src={chat?.medico.fotoPerfil!} size={5} />
                                <div className="message-container">{message?.mensagem}</div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>

            {/* <form onSubmit={handleSubmit} className="new-message-form">
                <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handlePressEnter}
                    placeholder="Digite a mensagem"
                    className="new-message-field"
                />
                <SendMessageIcon className="send-message-button" onClick={handleSubmit} />
            </form> */}
        </div>
    );
};

export default Messenger;
