import { useFormik } from 'formik';
import * as Yup from 'yup';
import api, { getUserDataForPasswordRecovery } from '../../../services/api';
import { IAlertParams } from '../../private/user/interface';
import { useEffect, useState } from 'react';
import { Input, Button } from 'reactstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalApp from '../../../components/modal';
import { LogoZeebras } from '../../../assets/icons/LogoZeebras';

export const CreateNewPassword = () => {
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [alertParams, setAlertParams] = useState<IAlertParams>();
    const [user, setUser] = useState({ uuid: '', name: '' });
    const location = useLocation();
    const navigate = useNavigate();

    const passwodSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'A senha deve conter ao menos oito caracteres')
            .matches(/[0-9]/, 'A senha deve conter ao menos um número')
            .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula')
            .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula')
            .matches(/[^\w]/, 'A senha deve conter ao menos um caracter especial')
            .required('Digite a sua nova senha'),
        confirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Senha não confere com a digitada acima')
            .required('Confirme a sua nova senha'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm: '',
        },
        validationSchema: passwodSchema,
        onSubmit: async (values) => {

            const newPassword = {
                senha: values.password,
            };
            try {
                await api.put(`paciente/update-password?uuid=${user.uuid}`, newPassword).then(() => {
                    setAlertParams({
                        active: true,
                        action: 'Cadastro realizado com sucesso!',
                        message: 'Sua nova senha foi cadastrada com sucesso!',
                        variant: 'success',
                    });
                });
            } catch (error) {
                setAlertParams({
                    active: true,
                    action: 'Erro ao cadastrar nova senha!',
                    message: 'Por favor, tente novamente mais tarde.',
                    variant: 'error',
                });
            }
        },
    });

    const getUserData = async (userId: string) => {
        const data = await getUserDataForPasswordRecovery({ uuid: userId });
        if (!data) navigate('/');
        // redirecionar o usuário para a tela de saudação caso não encontre o susuário
        setUser({ uuid: data.uuid, name: `${data.nome ?? ''} ${data.sobrenome ?? ''}` });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const uuid = searchParams.get('uuid');

        if (!uuid) {
            navigate('/');
        } else {
            getUserData(uuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <div className="default login min-zeebras">
            <div className="logo">
                <LogoZeebras />
            </div>

            <h2>Criar nova senha 🔒</h2>

            <p className="mb-3 mt-3">
                Olá, <strong>{user.name.trim()}</strong>! Para continuar com a <br />
                recuperação do seu aceso, siga os passos abaixo.
            </p>

            <form className="d-flex flex-column align-items-center pt-3" onSubmit={formik.handleSubmit}>

                <label>Insira a sua nova senha</label>
                <div className="form-codee">
                    <Input
                        type={viewPassword ? 'text' : 'password'}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />

                    {!viewPassword ? (
                        <AiOutlineEye onClick={() => setViewPassword(!viewPassword)} />
                    ) : (
                        <AiOutlineEyeInvisible onClick={() => setViewPassword(!viewPassword)} />
                    )}

                    {formik.touched.password && formik.errors.password ? (
                        <span className="validation-error">{formik.errors.password}</span>
                    ) : null}
                </div>

                <label>Confirme a sua nova senha</label>
                <div className="form-codee">
                    <Input
                        type={viewConfirmPassword ? 'text' : 'password'}
                        name="confirm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm}
                    />{' '}
                    {!viewConfirmPassword ? (
                        <AiOutlineEye onClick={() => setViewConfirmPassword(!viewConfirmPassword)} />
                    ) : (
                        <AiOutlineEyeInvisible onClick={() => setViewConfirmPassword(!viewConfirmPassword)} />
                    )}
                    {formik.touched.confirm && formik.errors.confirm ? (
                        <span className="validation-error">{formik.errors.confirm}</span>
                    ) : null}
                </div>
                <Button type="submit" disabled={!formik.isValid}>
                    Salvar
                </Button>
            </form>

            <Link to={'/auth'}>
                <p>&lt; Voltar para o Login</p>
            </Link>

            <div className="zeebras">
                <LogoZeebras />
            </div>

            {alertParams?.active && (
                <ModalApp
                    isOpenModal={alertParams?.active}
                    actionTitle={alertParams?.action}
                    actionBody={alertParams?.message}
                    handleConfirm={() => {
                        navigate('/auth');
                    }}
                />
            )}
        </div>
    );
};
