import { useFormik } from "formik";
import * as Yup from "yup";

import { Input, Button } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalApp from "../../../components/modal";
import { getUserDataForPasswordRecovery, sendEmailRecovery } from "../../../services/api";
import { toast } from "react-toastify";
import { LogoZeebras } from "../../../assets/icons/LogoZeebras";

export const PasswordRecovery = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigate = useNavigate();

    const emailSchema = Yup.object().shape({
        email: Yup.string()
            .email("Este email não é válido!")
            .required("Este campo é obrigatório!"),
    });

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: emailSchema,
        onSubmit: async (value) => {

            try {
                const userData = await getUserDataForPasswordRecovery({email: value.email});
                await sendEmailRecovery(userData);
                setIsOpenModal(true);
            } catch (error: any) {
                toast.error(error.response.data.message)
                console.log(error);
            }
        },
    });

    return (
        <div className="default forgot min-zeebras">
            <div className="logo">
                <Link to={"/"}>
                    <LogoZeebras />
                </Link>
            </div>

            <h2>Vamos lembrar os seus dados de acesso,</h2>

            <p>
                e para isso precisamos que você digite o e-mail
                <br />
                que você utilizou no cadastro em sua consulta.
            </p>

            <form onSubmit={formik.handleSubmit}>
                <div className="form-codee">
                    <Input
                        id="email"
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="validation-error">
                            {formik.errors.email}
                        </div>
                    ) : null}
                </div>

                <p>
                    Ao clicar no botão abaixo você irá receber seus
                    <br />
                    dados de acesso em seu e-mail.
                </p>

                <Button type="submit">Receber meus dados de acesso</Button>
            </form>
            <ModalApp
                isOpenModal={isOpenModal}
                actionTitle={"Email enviado com Sucesso!"}
                actionBody={
                    "Os dados de acesso foram enviados para o seu e-mail."
                }
                handleConfirm={() => {navigate('/')}}
            />

            <div className="zeebras">
                <LogoZeebras />
            </div>
        </div>
    );
};
