import VideoActivity from './videoActivity';
import { useAuth } from '../../../contexts/auth';
import { PatientInteractionInfo } from '../../../types/patienteInteraction';
import { createPatientInteractionRecord, getRehab } from '../../../services/api';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { IRehabActivitiesGroup } from '../../../interface';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const ActivityList: React.FC = () => {
    const { patient, rehab, setRehab } = useAuth();
    const navigate = useNavigate();

    const handleVideoInteration = async (data: PatientInteractionInfo) => {
        const currentInteractionTime = data.currentActivityInteractionMS;
        const startTime = data.interactionBegin.getTime();
        const finishTime = data.interactionEnd.getTime();
        const interationTimeInSeconds = finishTime - startTime;

        const interactionBody = {
            pacienteId: patient!.uuid,
            perguntaMedicoId: data.activityId,
            reabilitacaoId: rehab!.uuid,
            msInteracao: currentInteractionTime + interationTimeInSeconds,
        };

        try {
            await createPatientInteractionRecord(interactionBody);
            const updatedRehab = await getRehab(patient!.uuid);
            setRehab(updatedRehab);
            toast.success('Sua atividade foi salva com sucesso!')
        } catch (error) {
            toast.error('Houve um erro ao registrar a sua atividade. Tente novamente mais tarde!')
        }
    };

    const handleSelectActivityGroup = async (activities: IRehabActivitiesGroup) => {
        navigate('/rehab/activities-questions', {
            state: { activities },
        });
    };

    return (
        <div className="container min-zeebras">
            {rehab?.tipoAtividade === 'texto' ? (
                <div>
                    {rehab.fases_reabilitacao.map((session) =>
                        session.grupo_atividades.map((activityGroup, index) => {
                            return (
                                <div key={index} className="d-flex align-items-center pt-4">
                                    <div className="icon big me-4">
                                        <AiOutlineUnorderedList />
                                    </div>
                                    <div
                                        className="flex-fill"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleSelectActivityGroup(activityGroup);
                                        }}
                                    >
                                        <h4 className="f20 bold">{activityGroup.nome}</h4>
                                        <p className="f16">{activityGroup.descricao}</p>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            ) : (
                <div>
                    {rehab?.fases_reabilitacao.map((session) =>
                        session.grupo_atividades.map((activityGroup) =>
                            activityGroup.pergunta_medico.map((question, index) => {
                                return <VideoActivity key={index} question={question} event={handleVideoInteration} />;
                            })
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default ActivityList;
