import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputField } from '../../../../components/input';
import { FC, useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import api, { getUserData, updateUserData } from '../../../../services/api';
import { maskCpf, maskDate, maskPhone } from '../../../../utils/masks';
import { getFormData } from '../../../../utils/formDataMethods';
import { IChangeAvatar, IUserData } from '../interface';
import { useAuth } from '../../../../contexts/auth';
import { toast } from 'react-toastify';

export const UserDataUpdateForm: FC<IChangeAvatar> = ({ photo }) => {
    const [userData, setUserData] = useState<IUserData | undefined>();

    const { patient, setPatient, setIsLoading } = useAuth();


    useEffect(() => {
        api.get(`paciente/searchpaciente?uuid=${patient?.uuid}`).then(({data}) => {
            setUserData(data?.paciente[0]);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            toast.error('Erro ao buscar dados, tente novamente mais tarde.');
        })
    }, [patient?.uuid, setIsLoading]);


    const UserDataUpdateSchema = Yup.object().shape({
        nome: Yup.string()
            .min(2, 'O nome deve conter mais de dois caracteres')
            .max(50, 'O nome deve conter menos de 50 caracteres')
            .required('Este campo é obrigatório'),

        // sobrenome: Yup.string()
        //     .min(2, 'O sobrenome deve conter mais de dois caracteres')
        //     .max(50, 'O sobrenome deve conter menos de 50 caracteres')
        //     .required('Este campo é obrigatório'),

        cpf: Yup.string().min(11, 'O CPF deve conter 11 dígitos').required('Este campo é obrigatório'),

        rg: Yup.string(),

        telefone: Yup.string().required('Este campo é obrigatório'),

        email: Yup.string().email('E-mail inválido').required('Este campo é obrigatório'),

        dataNascimento: Yup.string().required('Este campo é obrigatório'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            uuid: userData?.uuid,
            nome: userData?.nome,
            // sobrenome: userData?.sobrenome,
            foto: userData?.foto,
            cpf: userData?.cpf || '',
            rg: userData?.rg,
            convenio: userData?.convenio,
            numeroConvenio: userData?.numeroConvenio,
            telefone: (userData?.telefone && maskPhone(userData.telefone)) || '',
            endereco: {
                bairro: userData?.endereco?.bairro || '',
                cep: userData?.endereco?.cep || '',
                cidade: userData?.endereco?.cidade || '',
                complemento: userData?.endereco?.complemento || '',
                estado: userData?.endereco?.estado || '',
                numero: userData?.endereco?.numero || '',
                rua: userData?.endereco?.rua || '',
                tipoEndereco: userData?.endereco?.tipoEndereco || '',
            },
            email: userData?.email,
            dataNascimento: userData?.dataNascimento || '',
        },

        validationSchema: UserDataUpdateSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const formValues = {
                objects: {
                    uuid: values.uuid,
                    nome: values.nome,
                    // sobrenome: values.sobrenome,
                    foto: photo ? photo : userData?.foto,
                    cpf: maskCpf(values.cpf),
                    rg: values.rg,
                    convenio: values.convenio,
                    numeroConvenio: values.numeroConvenio,
                    telefone: maskPhone(values.telefone),
                    email: values.email,
                    dataNascimento: values.dataNascimento,
                },
            };

            try {
                if (patient) {
                    await updateUserData(patient.uuid, getFormData(formValues));
                    const updatedUserData = await getUserData(patient.uuid);
                    setPatient(updatedUserData);
                    setIsLoading(false);
                    toast.success('Os seus dados foram alterados com sucesso.');
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
                toast.success('Erro ao atualizar dados, tente novamente mais tarde.');
            }
        },
    });

    return (
        <div>
            <form className="userDataForm" encType="multpart/form-data" onSubmit={formik.handleSubmit}>
                <Row>
                    <Col md={10}>
                        <InputField
                            name="nome"
                            label="Nome Completo"
                            value={formik?.values?.nome}
                            onChange={formik.handleChange}
                            helperText={formik.touched.nome && formik.errors.nome ? formik.errors.nome : ''}
                            className="form-control"
                        />
                    </Col>
                    {/* <Col md={5}>
                        <InputField
                            name="sobrenome"
                            label="Sobrenome"
                            value={formik.values.sobrenome}
                            onChange={formik.handleChange}
                            helperText={
                                formik.touched.sobrenome && formik.errors.sobrenome ? formik.errors.sobrenome : ''
                            }
                            className="form-control"
                        />
                    </Col> */}
                </Row>

                <Row>
                    <Col md={5}>
                        <InputField
                            name="cpf"
                            label="CPF"
                            maxLength={14}
                            value={maskCpf(formik.values.cpf)}
                            onChange={formik.handleChange}
                            helperText={formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : ''}
                            className="form-control"
                        />
                    </Col>
                    <Col md={5}>
                        <InputField
                            name="rg"
                            label="RG"
                            value={formik.values.rg}
                            onChange={formik.handleChange}
                            helperText={formik.touched.rg && formik.errors.rg ? formik.errors.rg : ''}
                            className="form-control"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={5}>
                        <InputField
                            name="convenio"
                            label="Convênio"
                            value={formik.values.convenio}
                            onChange={formik.handleChange}
                            helperText={formik.touched.convenio && formik.errors.convenio ? formik.errors.convenio : ''}
                            className="form-control"
                        />
                    </Col>
                    <Col md={5}>
                        <InputField
                            name="numeroConvenio"
                            label="Número do convênio"
                            value={formik.values.numeroConvenio}
                            onChange={formik.handleChange}
                            helperText={
                                formik.touched.numeroConvenio && formik.errors.numeroConvenio
                                    ? formik.errors.numeroConvenio
                                    : ''
                            }
                            className="form-control"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={5}>
                        <InputField
                            name="telefone"
                            label="Telefone"
                            maxLength={14}
                            value={maskPhone(formik.values.telefone)}
                            onChange={(e) => {
                                formik.handleChange(e);
                            }}
                            helperText={formik.touched.telefone && formik.errors.telefone ? formik.errors.telefone : ''}
                            className="form-control"
                        />
                    </Col>
                    <Col md={5}>
                        <InputField
                            name="cidade"
                            label="Cidade"
                            value={formik.values.endereco.cidade}
                            onChange={formik.handleChange}
                            readOnly
                            helperText={
                                formik.touched.endereco?.cidade && formik.errors.endereco?.cidade
                                    ? formik.errors.endereco.cidade
                                    : ''
                            }
                            className="form-control"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={5}>
                        <InputField
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                            className="form-control"
                        />
                    </Col>
                    <Col md={5}>
                        <InputField
                            name="dataNascimento"
                            label="Data de Nascimento"
                            maxLength={10}
                            value={maskDate(formik.values.dataNascimento)}
                            onChange={formik.handleChange}
                            helperText={
                                formik.touched.dataNascimento && formik.errors.dataNascimento
                                    ? formik.errors.dataNascimento
                                    : ''
                            }
                            className="form-control"
                        />
                    </Col>
                </Row>

                <Button type="submit">Salvar</Button>
            </form>
        </div>
    );
};
