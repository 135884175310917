import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LogoZeebras } from '../../../assets/icons/LogoZeebras';
import { useAuth } from '../../../contexts/auth';

export const Greeting = () => {
    const {setIsLoading} = useAuth();

    useEffect(() => {
        setIsLoading(false);
        const disconnected = Boolean(localStorage.getItem('@disconnected'));
        disconnected && toast.error('Sessão expirada. Faça login novamente!');
        localStorage.removeItem('@disconnected');
    }, [setIsLoading]);

    return (
        <div className="default presentation min-zeebras">
            <div className="logo">
                <LogoZeebras />
            </div>

            <h2>Seja bem vindo</h2>

            <p>
                A plataforma de reabilitação
                <br />
                mais <strong>equilibrada</strong> que existe.
            </p>

            <p>
                Através dela vamos realizar
                <br />o <strong>seu tratamento</strong>.
            </p>

            <h3>Vamos lá?</h3>

            <Link className="btn" to={'/auth'}>
                Vamos lá...
            </Link>

            <div className="zeebras">
                <LogoZeebras />
            </div>
        </div>
    );
};
