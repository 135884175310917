import { FC, useEffect, useState } from 'react';
import GenericAvatar from '../../../components/GenericAvatar';
import { AppNavBar } from '../../../components/navBar';
import { useAuth } from '../../../contexts/auth';
import api from '../../../services/api';
import { toast } from 'react-toastify';

interface IDoctorAdress {
    rua: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
}

interface IClienteAdress extends Array<IDoctorAdress> {}

interface IAllDoctorData {
    uuid: string;
    email: string;
    usuario: string;
    perfilDeUsuario: string;
    numeroRegistro: string;
    cliente: {
        enderecos: IClienteAdress;
    };
    nome: string;
    fotoPerfil: string;
    status: boolean;
    controleAcesso: boolean;
    telefone: string;
}

export const DoctorData: FC = () => {
    const [doctorData, setDoctorData] = useState<IAllDoctorData>();

    const { rehab, setIsLoading } = useAuth();


    useEffect(() => {
        api.get(`userclient/search?search=${rehab?.medico.uuid}`)
            .then(({ data }) => {
                setDoctorData(data.usuarios_cliente[0]);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error('Erro ao buscar os dados do profissional, tente novamente mais tarde');
            });
    }, [rehab?.medico.uuid, setIsLoading]);

    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4">
                <div className="d-flex align-items-center justify-content-center pb-4">
                    <GenericAvatar folder='usuariosCliente' src={doctorData?.fotoPerfil!} size={15} />
                    <h2 className="f36 bold ps-4">{doctorData?.nome}</h2>
                </div>

                <div className="d-flex flex-column p-5 mx-5">
                    {doctorData?.numeroRegistro && (
                        <div className="d-flex align-items-center pb-4">
                            <p className="f30 title-data">Número de Registro:</p>
                            <p className="flex-fill f30 bold">123.456.789-01</p>
                        </div>
                    )}

                    <div className="d-flex align-items-center pb-4">
                        <p className="f30 title-data">Telefone:</p>
                        <p className="flex-fill">
                            <span className="d-block border-data">{doctorData?.telefone}</span>
                        </p>
                    </div>

                    <div className="d-flex align-items-center pb-4">
                        <p className="f30 title-data">E-mail:</p>
                        <p className="flex-fill">
                            <span className="d-block border-data">{doctorData?.email}</span>
                        </p>
                    </div>

                    <div className="d-flex align-items-center pb-4">
                        <p className="f30 title-data">Endereço:</p>
                        <p className="flex-fill">
                            <span className="d-block border-data">{`${doctorData?.cliente.enderecos[0].rua}, Nº ${doctorData?.cliente.enderecos[0].numero} - ${doctorData?.cliente.enderecos[0].bairro} | ${doctorData?.cliente.enderecos[0].estado}`}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
