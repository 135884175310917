import ModalApp from '../../../components/modal';
import { useAuth } from '../../../contexts/auth';
import React, { useEffect, useState } from 'react';
import { AppNavBar } from '../../../components/navBar';
import { Checkbox } from '../../../components/checkBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImageRender } from '../../../components/imageRender/indext';
import { IRehabActivitiesGroup, RehabPatientAnswers } from '../../../interface';
import api, { createPatientInteractionRecord, getRehab, getRehabPatientAnswers } from '../../../services/api';

interface IActivitiesGroup {
    activities: IRehabActivitiesGroup;
}

interface IAlertParams {
    message: string;
    variant: string;
    active: boolean;
    action: string;
}

const QuestionActivity: React.FC = () => {
    const [patientAnswers, setPatientAnswers] = useState<RehabPatientAnswers[]>();
    const [alertParams, setAlertParams] = useState<IAlertParams>();
    const [startTimer, setStartTimer] = useState<Date>();

    const navigate = useNavigate();
    const { state } = useLocation();
    const { patient, rehab, setRehab } = useAuth();

    const { activities } = state as IActivitiesGroup;

    useEffect(() => {
        setStartTimer(new Date());
    }, []);

    useEffect(() => {
        if (patient) {
            getRehabPatientAnswers(patient?.uuid)
                .then((data) => {
                    setPatientAnswers(data);
                })
                .catch((err) => {
                    throw err;
                });
        }
    }, [patient]);

    const handlePatientInteraction = () => {
        const currentInteractionTime = activities.interacaoTexto?.msInteracao ?? 0;
        const startTime = startTimer?.getTime() ?? 0;
        const finishTime = new Date().getTime();
        const newInteractionPatient = finishTime - startTime;

        const interactionBody = {
            pacienteId: patient!.uuid,
            grupoAtividadesId: activities.uuid,
            reabilitacaoId: rehab!.uuid,
            msInteracao: currentInteractionTime + newInteractionPatient,
        };

        createPatientInteractionRecord(interactionBody).catch((err) => {
            // definir toaster de erro
            console.error(err);
        });
    };

    const handleChangeTextArea = (perguntaMedicoId: string, newValue: string) => {
        const index = patientAnswers!.findIndex((resposta) => resposta.perguntaMedicoId === perguntaMedicoId);
        if (index !== -1) {
            const patientAnswersCopy = [...patientAnswers!];
            patientAnswersCopy[index].resposta = newValue;
            setPatientAnswers(patientAnswersCopy);
        } else {
            setPatientAnswers([
                ...patientAnswers!,
                { pacienteId: patient!.uuid, perguntaMedicoId, resposta: newValue },
            ]);
        }
    };

    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        values: { perguntaMedicoId: string; resposta: string }
    ) => {
        if (e.target.checked) {
            const isInArray = patientAnswers?.some((el) => el.respostaMedicoId === e.target.value);
            if (!isInArray) {
                const newAnswer = {
                    ...values,
                    pacienteId: patient!.uuid,
                    respostaMedicoId: e.target.value,
                };
                setPatientAnswers([...patientAnswers!, newAnswer]);
            }
        } else {
            const answerIndex = patientAnswers?.findIndex((el) => el.respostaMedicoId === e.target.value);
            if (answerIndex !== -1) {
                const patientAnswersCopy = [...patientAnswers!];
                patientAnswersCopy.splice(answerIndex!, 1);
                setPatientAnswers(patientAnswersCopy);
            }
        }
    };

    const handleDeleteAnswer = async () => {
        if (!patient) throw new Error('Identificação do paciente não encontrada');
        const prevAnswers = await getRehabPatientAnswers(patient.uuid);

        if (prevAnswers && patientAnswers) {
            let answerToDelete = [];
            for (let i = 0; i < prevAnswers.length; i++) {
                const findAnswer = patientAnswers.find((el) => el.uuid === prevAnswers[i].uuid);
                if (!findAnswer) {
                    answerToDelete.push(prevAnswers[i].uuid);
                }
            }
            api.post(`repostaspaciente/delete`, answerToDelete).catch((err) => {
                throw err;
            });
        }
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            await handleDeleteAnswer();
            handlePatientInteraction();
            await api.post(`repostaspaciente/create`, patientAnswers);

            const updatedRehab = patient && (await getRehab(patient?.uuid));
            setRehab(updatedRehab);
            setAlertParams({
                message: 'Sua atividade foi salva com sucesso!',
                variant: 'success',
                active: true,
                action: 'Atividade concluída!',
            });
        } catch (error) {
            setAlertParams({
                message: 'Houve um erro ao registrar a sua atividade. Tente novamente mais tarde!',
                variant: 'error',
                active: true,
                action: 'Erro!',
            });
        }
    };

    return (
        <div className="container min-zeebras">
            <div className="content p-4">
                <AppNavBar />

                <h4 className="f28 pb-5">
                    <strong>{patient?.nome}</strong>, Vamos realizar o questionário que compõe o seu tratamento. Preste
                    atenção nas instruções e realize da forma mais efetiva possível esta parte do seu tratamento, assim
                    vamos melhorar juntos.
                </h4>
                <form onSubmit={handleSubmit}>
                    {activities.pergunta_medico.map((activity, index) => {
                        return (
                            <div key={index} style={{ marginBottom: '18px' }}>
                                <h3 className="f36 bold pb-4">{index + 1} - Questão</h3>
                                <p className="f28 pb-4">{activity.pergunta}</p>

                                {activity.tipoDeResposta === 'multipla' ? (
                                    activity.respostas.map((answer, aIndex) => (
                                        <div
                                            className="alternativeQuestion"
                                            style={{ display: 'flex', gap: '10px' }}
                                            key={aIndex}
                                        >
                                            {activity.imagem && (
                                                <ImageRender folder={'modelosReabilitacoes'} src={activity.imagem} />
                                            )}

                                            <Checkbox
                                                value={answer.uuid}
                                                isChecked={patientAnswers?.some(
                                                    (el) => el.respostaMedicoId === answer.uuid
                                                )}
                                                label={answer.respostaAlternativa}
                                                event={(e) =>
                                                    handleCheckboxChange(e, {
                                                        perguntaMedicoId: activity.uuid,
                                                        resposta: answer.respostaAlternativa,
                                                    })
                                                }
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="subjectiveQuestion">
                                        {activity.imagem && (
                                            <ImageRender folder={'modelosReabilitacoes'} src={activity.imagem} />
                                        )}

                                        <div className="form-message mt-2">
                                            <textarea
                                                className="form-control"
                                                name="answer"
                                                rows={6}
                                                value={
                                                    (patientAnswers &&
                                                        patientAnswers.find((a) => a.perguntaMedicoId === activity.uuid)
                                                            ?.resposta) ??
                                                    ''
                                                }
                                                onChange={(e) => handleChangeTextArea(activity.uuid, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <button className="btn btn-secondary" type="submit">
                        Enviar Minhas Respostas
                    </button>
                </form>
            </div>
            {alertParams && (
                <ModalApp
                    isOpenModal={alertParams.active}
                    actionTitle={alertParams.action}
                    actionBody={alertParams.message}
                    handleConfirm={() => navigate('/rehab/activities')}
                />
            )}
        </div>
    );
};

export default QuestionActivity;
