import React, { useEffect, useState } from 'react';
import AvatarImg from '../../assets/images/default-avatar.png';
import { fetchImage } from '../../utils/fecthImage';
import './styles.css';

interface IAvatarProps {
    folder?: string;
    src: string;
    size: number;
}

const GenericAvatar: React.FC<IAvatarProps> = ({ folder, src, size }) => {
    const [img, setImg] = useState<string>();
    const imgWidth = size * 10;

    useEffect(() => {
        if (folder) {
            fetchImage(folder, src).then((image) => {
                setImg(image);
            });
        } else {
            setImg(src);
        }
    }, [folder, src]);

    return (
        <div className="gen-avatar-container">
            <div className="gen-avatar-preview">
                <img
                    src={img ? img : AvatarImg}
                    alt="Doctor"
                    className="gen-image-preview"
                    style={{ width: `${imgWidth}px`, height: `${imgWidth}px` }}
                />
            </div>
        </div>
    );
};

export default GenericAvatar;
