import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "./style.css";

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

interface IParms {
    limit: number;
    total: number;
    offset: number;
    setOffset: Function;
}

export const Pagination = ({ limit, total, offset, setOffset }: IParms) => {
    const current = offset ? offset / limit + 1 : 1;
    const pages = Math.ceil(total / limit);
    const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
    const first = Math.min(Math.max(current - MAX_LEFT, 1), maxFirst);

    function onPageChange(page: number) {
        setOffset((page - 1) * limit);
    }

    return (
        <div className="pagination-container">
            <button
                className="prev-page"
                onClick={() => onPageChange(current - 1)}
                disabled={current === 1}
            >
                <BsChevronLeft />
            </button>

            <ul className="pagination">
                {Array.from({ length: Math.min(MAX_ITEMS, pages) })
                    .map((_, index) => index + first)
                    .map((page) => (
                        <li key={page}>
                            <button
                                onClick={() => onPageChange(page)}
                                className={
                                    page === current
                                        ? "pagination__item--active"
                                        : ""
                                }
                            >
                                {page}
                            </button>
                        </li>
                    ))}
            </ul>

            <button
                className="next-page"
                onClick={() => onPageChange(current + 1)}
                disabled={current === pages}
            >
                <BsChevronRight />
            </button>
        </div>
    );
};
