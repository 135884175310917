import { FC, ImgHTMLAttributes, useEffect, useState } from 'react';
import { fetchImage } from '../../utils/fecthImage';

interface IImageRenderProps extends ImgHTMLAttributes<HTMLElement> {
    folder: string;
    src: string;
}

export const ImageRender: FC<IImageRenderProps> = ({ src, folder, ...props }: IImageRenderProps) => {
    const [img, setImg] = useState<string>();

    useEffect(() => {
        fetchImage(folder, src)
            .then((data) => {
                setImg(data);
            })
            .catch((err) => {
                throw err;
            });
    }, [folder, src]);

    return (
        <div className="mb-2" style={{ display: 'block', width: '360px' }}>
            <img src={img} alt="Imagem" style={{ borderRadius: '20px', width: '100%' }} {...props} />
        </div>
    );
};
