import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/auth';
import ModalApp from '../../../../components/modal';
import { InputField } from '../../../../components/input';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useFormik } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../../../services/api';
import { IAlertParams } from '../interface';
import { toast } from 'react-toastify';

export const ChangePasswordForm = () => {
    const { Logout, setIsLoading } = useAuth();
    const [alertParams, setAlertParams] = useState<IAlertParams>();
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const { patient } = useAuth();

    const navigate = useNavigate();

    const PasswodSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'A senha deve conter ao menos oito caracteres'),
            // .matches(/[0-9]/, 'A senha deve conter ao menos um número')
            // .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula')
            // .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula')
            // .matches(/[^\w]/, 'A senha deve conter ao menos um caracter especial'),
        confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Senha não confere com a digitada acima'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm: '',
        },
        validationSchema: PasswodSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const newPassword = {
                senha: values.password,
            };
            try {
                await api.put(`paciente/update-password?uuid=${patient?.uuid}`, newPassword);
                setIsLoading(false);
                setAlertParams({
                    active: true,
                    action: 'A sua senha foi alterada com sucesso!',
                    message: 'Por favor, inicie uma nova sessão.',
                    variant: 'success',
                });
            } catch (error) {
                toast.error('Erro ao alterar senha, tente novamente mais tarde.')
            }
        },
    });

    return (
        <div className="form-codee">
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col className="position-relative" md={8}>
                        <InputField
                            type={viewPassword ? 'text' : 'password'}
                            name="password"
                            label={'Altere sua senha'}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                            className="form-control"
                        />
                        {!viewPassword ? (
                            <AiOutlineEye className="view-password" onClick={() => setViewPassword(!viewPassword)} />
                        ) : (
                            <AiOutlineEyeInvisible
                                className="view-password"
                                onClick={() => setViewPassword(!viewPassword)}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col className="position-relative" md={8}>
                        <InputField
                            type={viewConfirmPassword ? 'text' : 'password'}
                            name="confirm"
                            label={'Digite sua senha novamente'}
                            onChange={formik.handleChange}
                            value={formik.values.confirm}
                            helperText={formik.touched.confirm && formik.errors.confirm ? formik.errors.confirm : ''}
                            className="form-control"
                        />
                        {!viewConfirmPassword ? (
                            <AiOutlineEye
                                className="view-password"
                                onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                            />
                        ) : (
                            <AiOutlineEyeInvisible
                                className="view-password"
                                onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                            />
                        )}
                    </Col>
                </Row>

                <Button type="submit">Salvar</Button>
            </form>

            {alertParams?.active && (
                <ModalApp
                    isOpenModal={alertParams?.active}
                    actionTitle={alertParams?.action}
                    actionBody={alertParams?.message}
                    handleConfirm={() => { Logout(); navigate('/');}}
                />
            )}
        </div>
    );
};
