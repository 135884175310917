import { createContext, useEffect, useState, useContext } from 'react';
import { IAuthContextData, IRehab, PatientData } from '../interface';
import { getRehab, getUserData } from '../services/api';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

interface AuthContextProps {
    children: React.ReactNode;
}
export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
    const [patient, setPatient] = useState<PatientData | null>(null);
    const [rehab, setRehab] = useState<IRehab | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const storagedPatient = localStorage.getItem('@App:paciente');
        //const storagedToken = sessionStorage.getItem('@App:token');
        if (/* storagedToken && */ storagedPatient) {
            updateData(JSON.parse(storagedPatient).uuid)
            //api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        }
    }, []);

    const updateData = async (patientId: string) => {
        const userData = await getUserData(patientId)
        const rehab = await getRehab(patientId)

        setPatient(userData)
        setRehab(rehab)
    }

    const Logout = () => {
        setIsLoading(true);
        setPatient(null);
        localStorage.removeItem('@App:paciente');
    };

    const getPatientInteraction = () => {
        const { interacaoPorTexto, interacaoPorVideo } = rehab!;
        const result = interacaoPorTexto.aggregate.sum.tempoInteracaoPorTexto +
        interacaoPorVideo.aggregate.sum.tempoInteracaoPorVideo;
        return result;
    }

    return (
        <AuthContext.Provider
            value={{ signed: Boolean(patient), patient, setPatient, rehab, setRehab, Logout, getPatientInteraction, isLoading, setIsLoading }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
}
