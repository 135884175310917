import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { useAuth } from '../../../contexts/auth';
import { getAuth, getRehab, getUserData } from '../../../services/api';
import { toast } from 'react-toastify';
import { LogoZeebras } from '../../../assets/icons/LogoZeebras';

export const Auth = () => {
    const [viewPassword, setViewPassword] = useState(false);
    const { setPatient, setRehab, setIsLoading } = useAuth();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email('Este email não é válido!').required('Este campo é obrigatório!'),
        password: yup.string().required('Este campo é obrigatório!'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const userAuth = await getAuth(values.email, values.password);
                const userData = await getUserData(userAuth.uuid);
                const rehab = await getRehab(userAuth.uuid)

                localStorage.setItem('@App:paciente', JSON.stringify(userAuth));
                //localStorage.setItem('@App:token', data.token);

                setPatient(userData);
                setRehab(rehab)
                setIsLoading(false);
                toast.info(`Bem-vindo, ${userAuth.nome}!`);
                navigate('/home');
            } catch (error: any) {
                setPatient(null);
                setIsLoading(false);
                console.log(error)
                toast.error(`Falha na autenticação: ${error.response.data.message}`);
                localStorage.removeItem('@App:paciente');
            }
        },
    });

    return (
        <div className="default login min-zeebras">
            <div className="logo">
                <LogoZeebras />
            </div>

            <h2>Me diga,</h2>

            <form className="d-flex flex-column align-items-center" onSubmit={formik.handleSubmit}>
                <p>
                    qual é o seu <strong>nome de acesso?</strong>
                </p>

                <div className="form-codee">
                    <Input
                        type="text"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <span className="validation-error">{formik.errors.email}</span>
                    ) : null}
                </div>

                <p>
                    E qual é a sua <strong>senha de acesso?</strong>
                </p>

                <div className="form-codee">
                    <Input
                        type={viewPassword ? 'text' : 'password'}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />{' '}
                    {!viewPassword ? (
                        <AiOutlineEye onClick={() => setViewPassword(!viewPassword)} />
                    ) : (
                        <AiOutlineEyeInvisible onClick={() => setViewPassword(!viewPassword)} />
                    )}
                    {formik.touched.password && formik.errors.password ? (
                        <span className="validation-error">{formik.errors.password}</span>
                    ) : null}
                </div>

                <h2>Vamos começar?</h2>
                <Button type="submit">Sim!</Button>
            </form>

            <Link to={'/password-recovery'}>Quer recuperar seus dados de acesso?</Link>

            <div className="zeebras">
                <LogoZeebras />
            </div>
        </div>
    );
};
