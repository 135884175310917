import { Fragment } from 'react';
import Loading from '../components/Loading';
import { useAuth } from '../contexts/auth';
import { Auth } from '../pages/login/auth';
import { CreateNewPassword } from '../pages/login/createNewPassword';
import { Greeting } from '../pages/login/main';
import { PasswordRecovery } from '../pages/login/passwordRecovery';
import { CallReport } from '../pages/private/dashboard/activityReport';
import { Dashboard } from '../pages/private/dashboard/main';
// import { CreateOrUpdateRecord } from '../pages/private/diary/createOrUpdateRecord';
// import { Diary } from '../pages/private/diary/main';
import { DoctorData } from '../pages/private/doctorData';
import MessengerPage from '../pages/private/messenger';
import { RecordsList } from '../pages/private/recordsList';
import { Activities } from '../pages/private/rehab/activities';
import { Rehab } from '../pages/private/rehab/main';
import QuestionActivity from '../pages/private/rehab/questionActivity';
import { CreateOrUpdateReport } from '../pages/private/report/createOrUpdateReport';
import { User } from '../pages/private/user/main';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

const AppRoutes: React.FC = () => {
    const { signed, isLoading } = useAuth();

    return (
        <Fragment>
            {isLoading && <Loading />}
            <Router>
                <Routes>
                    {/* #### Wildcard Route */}
                    <Route path="*" element={<Navigate to="/home" replace />} />
                    {/* #### Public Routes #### */}
                    <Route path="/" element={!signed ? <Greeting /> : <Navigate to="/home" />} />
                    <Route path="/auth" element={!signed ? <Auth /> : <Navigate to="/home" />} />
                    <Route path="/password-recovery" element={!signed ? <PasswordRecovery /> : <Navigate to="/home" />}/>
                    <Route path="pages/reset-password" element={!signed ? <CreateNewPassword /> : <Navigate to="/home" />}/>
                    {/* #### Private Routes #### */}
                    <Route path="/home" element={signed ? <Rehab /> : <Navigate to="/" />} />
                    <Route path="/dashboard" element={signed ? <Dashboard /> : <Navigate to="/" />} />
                    <Route path="/dashboard/activity-report" element={signed ? <CallReport /> : <Navigate to="/" />} />
                    <Route path="/rehab/activities" element={signed ? <Activities /> : <Navigate to="/" />} />
                    <Route path="/rehab/activities-questions" element={signed ? <QuestionActivity /> : <Navigate to="/" />}/>
                    {/* <Route path="/diary" element={signed ? <Diary /> : <Navigate to="/" />} /> */}
                    {/* <Route path="/diary/record" element={signed ? <CreateOrUpdateRecord /> : <Navigate to="/" />} /> */}
                    <Route path="/report" element={signed ? <CreateOrUpdateReport /> : <Navigate to="/" />} />
                    <Route path="/records-list" element={signed ? <RecordsList /> : <Navigate to="/" />} />
                    <Route path="/professional" element={signed ? <DoctorData /> : <Navigate to="/" />} />
                    <Route path="/messenger" element={signed ? <MessengerPage /> : <Navigate to="/" />} />
                    <Route path="/user" element={signed ? <User /> : <Navigate to="/" />} />
                </Routes>
            </Router>
        </Fragment>
    );
};

export default AppRoutes;
