import moment from 'moment';
import { FC } from 'react';
import { useAuth } from '../../contexts/auth';

export const InterationTimer: FC = () => {
    const { rehab } = useAuth();

    function iteractionTimeFormatter() {
        const totalInteractionInMs =
            (rehab?.interacaoPorTexto.aggregate.sum.tempoInteracaoPorTexto ?? 0) +
            (rehab?.interacaoPorVideo.aggregate.sum.tempoInteracaoPorVideo ?? 0);
        const hours = Math.floor(totalInteractionInMs / 3600000); // 1 hora = 3600000 ms
        const minutes = Math.floor((totalInteractionInMs % 3600000) / 60000); // 1 minuto = 60000 ms

        // Formata os valores para terem sempre 2 dígitos
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedTimer = `${formattedHours}:${formattedMinutes}`;

        return formattedTimer;
    }

    return (
        <div className="float-right pt-2">
            <div className="d-flex justify-content-between pb-2">
                <p className="f18">
                    INÍCIO:{' '}
                    {rehab?.iniciadaEm ? moment(rehab.iniciadaEm).format(`DD/MM/YY - HH:mm:ss`) : '00/00/00 - 00:00:00'}
                </p>
                <p className="f18">
                    TÉRMINO:{' '}
                    {rehab?.concluidaEm
                        ? moment(rehab.concluidaEm).format('DD/MM/YY - HH:mm:ss')
                        : '00/00/00 - 00:00:00'}
                </p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <p className="f18">O seu tempo de interação até o momento é:</p>
                <p className="interaction-time">
                    <span>{iteractionTimeFormatter() ?? '00:00'}</span> min
                </p>
            </div>
        </div>
    );
};
