import { useState } from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { AppNavBar } from '../../../../components/navBar';

import ArrowTopImg from '../../../../assets/images/arrowTop.png';
import { AiOutlineClose } from 'react-icons/ai';

export const Rehab = () => {
    const [isVideo, setIsVideo] = useState(false);
    const toggle = () => setIsVideo(!isVideo);

    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4">
                <Row>
                    <Col size="12">
                        <h2 className="f36 pb-4">
                            Vamos lá! Esta é a sua reabilitação <br /> e para dar início basta observar as <br />
                            orientações e realizar os passos necessários.
                        </h2>

                        <div className="d-flex flex-column align-items-center py-3">
                            <img src={ArrowTopImg} alt="Seta" style={{ transform: 'rotate(-30deg)' }} />
                        </div>

                        <p className="f24 pb-4 text-end">
                            Caso tenha dúvidas de como começar, <br /> temos um vídeo explicando a interação.
                        </p>

                        <div className="d-flex flex-column align-items-end py-3">
                            <img
                                src={ArrowTopImg}
                                alt="Seta"
                                style={{ marginRight: 30, transform: 'rotate3d(-0.6, 2, 0, 180deg)' }}
                            />
                        </div>

                        <div className="d-flex align-items-center justify-content-between py-4">
                            <p className="f24 pe-4">Deseja assistir ao vídeo?</p>

                            <BsChevronRight size={24} />

                            {/* <p className="f20 mt-0">Deseja ver o vídeo?</p> */}
                            {/* Abrir modal com o vídeo */}
                            <button className="btn-yes" onClick={toggle}>
                                Sim
                            </button>

                            <Link className="btn-no" to={'/rehab/activities'} onClick={toggle}>
                                Não
                            </Link>
                        </div>

                        <Modal isOpen={isVideo} size="xl">
                            <ModalBody>
                                <Button
                                    className="close"
                                    onClick={() => {
                                        toggle();
                                    }}
                                >
                                    <AiOutlineClose />
                                </Button>
                                <p className="f16 text-center pt-2">
                                    <strong>Introdução ao Zeebras</strong>
                                </p>
                                <hr></hr>
                                <iframe
                                    src={`https://video.bunnycdn.com/embed/158154/0be6a133-04f4-42b0-b9e0-64b1a3178958`}
                                    title="{activity.nomeVideo}"
                                    allowFullScreen
                                    width="100%"
                                    height="480px"
                                ></iframe>
                                {/* <p className="f16 text-center pt-2">
                                    É primordial que o vídeo seja visto em <strong>TELA INTEIRA</strong>.
                                </p> */}
                            </ModalBody>
                        </Modal>

                        {/* {isVideo && (
                            <div className="d-flex flex-column pt-4">
                                <p className="d-flex align-self-center f26 py-4">
                                    A segunda opção é entrar em contato com o seu médico pelo
                                    <br />
                                    recurso Mensagens ou pelo telefone que você encontra
                                    <br />
                                    na opção Dados do seu Médico.
                                </p>

                                <div className="d-flex justify-content-around p-4">
                                    <Link className="btn btn-left text-start" to={'/professional'}>
                                        Deseja ver os Dados
                                        <br />
                                        do seu Médico?
                                    </Link>

                                    <img src={ArrowLeftImg} alt="Seta" />

                                    <img src={ArrowRightImg} alt="Seta" />

                                    <Link className="btn btn-right text-end" to={''}>
                                        Deseja enviar
                                        <br />
                                        mensagens para o<br />
                                        seu Médico?
                                    </Link>
                                </div>
                                <p className="d-flex align-self-center f26 py-4">
                                    <Link to={'/rehab/activities'}>Seguir para o painel de reabilitação</Link>
                                </p>
                            </div>
                        )} */}
                    </Col>
                </Row>
            </div>
        </div>
    );
};
