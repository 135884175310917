import { AppNavBar } from '../../../components/navBar';
import Messenger from '../../../components/Messenger';
import DoctorImage from '../../../assets/images/imagem-dra-exemplo.png';
import './styles.css';
import { ImageRender } from '../../../components/imageRender/indext';
import { useAuth } from '../../../contexts/auth';
import MapIcon from '../../../assets/icons/Map';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { IDoctorData } from '../../../interface';
import LetterIcon from '../../../assets/icons/LetterIcon';
import { toast } from 'react-toastify';

const MessengerPage = () => {
    const [doctorData, setDoctorData] = useState<IDoctorData>();
    const { rehab, setIsLoading} = useAuth();

    useEffect(() => {
        api.get(`userclient/search?search=${rehab?.medico.uuid}`)
            .then(({ data }) => {
                setDoctorData(data.usuarios_cliente[0]);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error('Erro ao buscar registros, tente novamente mais tarde.')
            });
    }, [rehab?.medico.uuid]);

    const changeTitle = (gender: string | undefined) => {
        switch (gender) {
            case 'Feminino':
                return 'Dra.';
            case 'Masculino':
                return 'Dr.';
            default:
                return 'Dr.';
        }
    };

    return (
        <div className="container">
            <AppNavBar />
            <div className="chat-container">
                <Messenger />

                <div className="info-card">
                    <div className="image-container">
                        {doctorData?.fotoPerfil ? (
                            <ImageRender folder={'usuariosCliente'} src={doctorData?.fotoPerfil} />
                        ) : (
                            <img src={DoctorImage} alt="" />
                        )}
                    </div>
                    <div className="chat-info">
                        <div className="doctor-info">
                            <p>
                                {changeTitle(doctorData?.sexo)}{' '}
                                <strong>{`${doctorData?.nome} ${doctorData?.sobrenome ?? ''}`}</strong>
                            </p>
                            <p style={{ fontSize: '14px' }}>
                                <MapIcon />{' '}
                                {`${doctorData?.cliente.enderecos[0].cidade} - ${doctorData?.cliente.enderecos[0].estado}`}
                            </p>
                            {doctorData?.crm && (
                                <p style={{ fontSize: '14px' }}>
                                    <strong>CRM:</strong> {doctorData?.crm}
                                </p>
                            )}
                        </div>
                        <div className="doctor-contacts">
                            <div style={{ fontSize: '14px' }}>
                                <LetterIcon /> {doctorData?.email}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <LetterIcon /> {doctorData?.telefone}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessengerPage;
