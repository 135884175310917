import * as React from 'react';

const UserIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20.8327 22.375V20.2917C20.8327 19.1866 20.3937 18.1268 19.6123 17.3454C18.8309 16.564 17.7711 16.125 16.666 16.125H8.33268C7.22761 16.125 6.16781 16.564 5.3864 17.3454C4.605 18.1268 4.16602 19.1866 4.16602 20.2917V22.375"
                stroke="#637194"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5007 11.9583C14.8018 11.9583 16.6673 10.0929 16.6673 7.79167C16.6673 5.49048 14.8018 3.625 12.5007 3.625C10.1995 3.625 8.33398 5.49048 8.33398 7.79167C8.33398 10.0929 10.1995 11.9583 12.5007 11.9583Z"
                stroke="#637194"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default UserIcon;
