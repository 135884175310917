import "./style.css";

interface IParam {
    getValue: (value: number) => void;
    rate: number | undefined;
}
export const Rating = ({ getValue, rate }: IParam) => {
    return (
        <div className="rating-container">
            <ul>
                {[...Array(10)]
                    .map((_, index) => index + 1)
                    .map((value) => (
                        <li className="rating-container" key={value}>
                            <button
                                type="button"
                                onClick={() => getValue(value)}
                                className={
                                    rate === value ? "rating__item--active" : ""
                                }
                            >
                                {value}
                            </button>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
