/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import AvatarImg from '../../assets/images/default-avatar.png';
import PencilIcon from '../../assets/images/vector-pencil.png';
import { useAuth } from '../../contexts/auth';
import './style.css';
interface IAvatarUpdate {
    onFileSelectSuccess: (e: any) => void;
    onFileSelectError: (e: any) => void;
}

export const AvatarUpdate: FC<IAvatarUpdate> = ({ onFileSelectSuccess, onFileSelectError }) => {
    const [selectedFile, setSelectedFile] = useState('');
    const { patient } = useAuth();

    useEffect(() => {
        getUserAvatar();
    }, [patient]);

    const getUserAvatar = () => {
        const image = patient?.foto
        image && setSelectedFile(image);

    };

    const handleFileInput = (file: any) => {
        if (file && file.size > 1024 * 100) {
            onFileSelectError({
                error: 'Erro! O tamanho do arquivo não pode ser superior a 1MB',
            });
        } else {
            onFileSelectSuccess(file);
            const imageObjectURL: any = URL.createObjectURL(file);
            setSelectedFile(imageObjectURL);
        }
    };

    return (
        <div className="avatar-upload-container">
            <div className="avatar-upload">
                <div className="avatar-edit">
                    <input
                        type="file"
                        id="imageUpload"
                        name="imageUpload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            handleFileInput(e.target.files && e.target.files[0]);
                        }}
                    />
                    <label htmlFor="imageUpload">
                        <img src={PencilIcon} alt="pencil" />
                    </label>
                </div>

                <figure className="avatar-preview">
                    <img src={!selectedFile ? AvatarImg : selectedFile} className="image-preview" alt="avatar" />
                </figure>
            </div>
        </div>
    );
};
