import * as React from "react";

export const MessageSquare = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="92"
            height="92"
            viewBox="0 0 92 92"
            fill="none"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M91 61.5C91 64.1522 89.9464 66.6957 88.0711 68.5711C86.1957 70.4464 83.6522 71.5 81 71.5H21L1 91.5V11.5C1 8.84784 2.05357 6.3043 3.92893 4.42893C5.8043 2.55357 8.34784 1.5 11 1.5H81C83.6522 1.5 86.1957 2.55357 88.0711 4.42893C89.9464 6.3043 91 8.84784 91 11.5V61.5Z"
                stroke="#637194"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
