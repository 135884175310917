import React, { useEffect, useState } from 'react';
import './styles.css';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    isChecked?: boolean;
    event(values: React.ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ isChecked, label, event, ...res }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        isChecked && setChecked(isChecked);
    }, [isChecked]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked);
        event(e);
    };

    return (
        <div className="checkbox-container">
            <div className={`checkbox ${checked && 'checked'}`}>
                <input type="checkbox" checked={checked} onChange={handleChange} {...res} />
            </div>
            <label htmlFor="">{label}</label>
        </div>
    );
};
