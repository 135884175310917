import { FC, useState } from 'react';
import { IRehabQuestion } from '../../../interface';
import { Button, Modal, ModalBody } from 'reactstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { PatientInteractionInfo } from '../../../types/patienteInteraction';

interface VideoProps {
    question: IRehabQuestion;
    event(values: PatientInteractionInfo): void;
}

const VideoActivity: FC<VideoProps> = ({ question, event }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [startTimer, setStartTimer] = useState<Date>();

    const videoInfo = {
        id: question.videoStream,
        title: question.nomeVideo,
        libraryId: 158154,
        directPlayUrl: 'https://video.bunnycdn.com/embed',
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSelect = () => {
        setStartTimer(new Date());
        setIsModalOpen(true);
    };

    return (
        <div className="d-flex align-items-center pt-4 m-3">
            <div
                style={{ cursor: 'pointer' }}
                className="thumbnailStream me-4"
                onClick={() => {
                    handleSelect();
                }}
            >
                <img src={question.thumbnailStream} className="img-fluid" alt="" />
            </div>
            <div className="flex-fill">
                <h4 className="f20 bold">{question.nomeVideo}</h4>
                <p className="f16">{question.descricaoVideo}</p>
            </div>

            {videoInfo && (
                <Modal isOpen={isModalOpen} size="xl">
                    <ModalBody>
                        <Button
                            className="close"
                            onClick={() => {
                                toggleModal();
                                event({
                                    interactionBegin: startTimer!,
                                    interactionEnd: new Date(),
                                    activityId: question.uuid,
                                    currentActivityInteractionMS: question.interacaoVideo?.msInteracao ?? 0,
                                });
                            }}
                        >
                            <AiOutlineClose />
                        </Button>
                        <p className="f16 text-center pt-2">
                            <strong>{videoInfo.title}</strong>
                        </p>
                        <hr></hr>
                        <iframe
                            src={`${videoInfo.directPlayUrl}/${videoInfo.libraryId}/${videoInfo.id}`}
                            title="{activity.nomeVideo}"
                            allowFullScreen
                            width="100%"
                            height="480px"
                        ></iframe>
                        <p className="f16 text-center pt-2">
                            É primordial que o vídeo seja visto em <strong>TELA INTEIRA</strong>.
                        </p>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default VideoActivity;
