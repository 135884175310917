/* eslint-disable react-hooks/exhaustive-deps */
import api from '../../../services/api';
import moment from 'moment';
import { SlNote } from 'react-icons/sl';
import { FiDelete } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../components/pagination';
import { Row, Col, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import { MessageSquare } from '../../../assets/icons/MessageSquare';

import { AppNavBar } from '../../../components/navBar';
import ModalApp from '../../../components/modal';
import { useAuth } from '../../../contexts/auth';
import { toast } from 'react-toastify';

interface IRecords {
    diario: [{}];
    ordenate: {
        currentPage: number;
        pageSize: number;
        totalItem: number;
        totalPage: number;
    };
}
interface IRecord {
    atualizadoEm: Date;
    avaliacao: number;
    criadoEm: Date;
    tipo: string;
    deletado: boolean;
    pacienteId: string;
    relato: string;
    titulo: string;
    uuid: string;
}

interface IAlertParams {
    message: String;
    variant: String;
    active: Boolean;
}

const ITENS_PER_PAGE = 5;

export const RecordsList = () => {
    const { patient, setIsLoading } = useAuth();
    const [records, setRecords] = useState<IRecords | undefined>();
    const [offset, setOffset] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<IRecord>();
    const [isDelete, setIsDelete] = useState(false);
    const [alertParams, setAlertParams] = useState<IAlertParams>();
    const navigate = useNavigate();

    useEffect(() => {
        getRecords();
    }, [offset]);


    const modalSwitch = () => setIsOpenModal(!isOpenModal);

    //Criar métodos GET, POST, PUT em services/axios
    const getRecords = () => {
        api.get(
                `diario/search?search=${patient?.uuid}&limit=${ITENS_PER_PAGE}&offset=${offset}`
            ).then(({data}) => {
                setRecords(data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.error('Erro ao listar registros, tente novamente mais tarde.')
            });
    };

    const handleView = (record: IRecord) => {
        setSelectedRecord(record);
        modalSwitch();
    };

    const handleUpdate = (record: IRecord) => {
        if (record.tipo === 'pensamento') {
            navigate('/diary/record', {
                state: { record },
            });
        } else {
            navigate('/report', {
                state: { record },
            });
        }
    };
    //Criar clean up para o setTimeout
    const handleDelete = async (record: IRecord) => {
        try {
            await api.put(`diario/delete?uuid=${record.uuid}`);
            setIsDelete(false);
            setAlertParams({
                message: 'Registro excluido com sucesso!',
                variant: 'success',
                active: true,
            });
            getRecords();
            setTimeout(() => {
                setAlertParams({
                    message: '',
                    variant: '',
                    active: false,
                });
            }, 4000);
        } catch (error) {
            console.log(error);
            setAlertParams({
                message: 'Houve um erro ao excluir o registro, tente novamente mais tarde!',
                variant: 'error',
                active: true,
            });
            setIsDelete(false);
            setTimeout(() => {
                setAlertParams({
                    message: '',
                    variant: '',
                    active: false,
                });
            }, 4000);
        }
    };
    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content">
                <Row>
                    <Col>
                        {!records?.diario.length ? (
                            <section className="d-flex flex-column align-items-center text-center">
                                <MessageSquare className="my-4" />

                                <p className="f36 semi-bold py-4">
                                    Você ainda não tem registros no seu diário.
                                    <br />
                                    Mas não se preocupe! Faça um
                                    <br />
                                    relato agora mesmo clicando no
                                    <br />
                                    botão abaixo, ou se preferir, volte ao
                                    <br />
                                    início.
                                </p>

                                <Link className="btn my-4 py-2" to={'/diary/record'}>
                                    <span className="f25">Fazer meu primeiro relatório</span>
                                </Link>

                                <Link className="btn py-2" to={'/dashboard'}>
                                    <span className="f25">Voltar</span>
                                </Link>
                            </section>
                        ) : (
                            <section className="d-flex flex-column p-4">
                                <h2 className="f36">Aqui estão listados os seus relatos.</h2>
                                <p className="f20 pb-4">Você poderá editar ou excluir os mesmos.</p>

                                {alertParams?.active && (
                                    <Alert
                                        className="mb-4"
                                        color={alertParams?.variant === 'success' ? 'success' : 'danger'}
                                    >
                                        {alertParams?.message}
                                    </Alert>
                                )}

                                {records &&
                                    records.diario.map((record: any) => (
                                        <div key={record.uuid}>
                                            <div className="d-flex pb-4">
                                                <div className="d-flex flex-column flex-fill">
                                                    <h3 className="f36 pb-2">
                                                        <strong>{record.titulo}</strong> | Dia{' '}
                                                        {moment(record.criadoEm).format('DD/MM/YYYY')}
                                                    </h3>
                                                    {record.avaliacao && (
                                                        <div className="d-flex align-items-center">
                                                            <p className="fdefault">
                                                                Nível de satisfação com a atividade:
                                                            </p>{' '}
                                                            <span className="rating">{record.avaliacao}</span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="d-flex align-items-center buttons-icons">
                                                    <AiOutlineEye
                                                        key={record.uuid}
                                                        onClick={() => handleView(record)}
                                                    />{' '}
                                                    <SlNote
                                                        onClick={() => {
                                                            setSelectedRecord(record);
                                                            handleUpdate(record);
                                                        }}
                                                    />{' '}
                                                    <FiDelete
                                                        onClick={() => {
                                                            setSelectedRecord(record);
                                                            setIsDelete(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="divider mb-4"></div>
                                        </div>
                                    ))}

                                {records?.ordenate.totalItem && (
                                    <Pagination
                                        limit={ITENS_PER_PAGE}
                                        total={records.ordenate.totalItem}
                                        offset={offset}
                                        setOffset={setOffset}
                                    />
                                )}
                                {selectedRecord && (
                                    <ModalApp
                                        isOpenModal={isOpenModal}
                                        actionTitle={selectedRecord?.titulo}
                                        actionBody={selectedRecord?.relato}
                                        handleConfirm={() => setIsOpenModal(!isOpenModal)}
                                    />
                                )}

                                <ModalApp
                                    isOpenModal={isDelete}
                                    actionTitle={`EXCLUIR RELATO`}
                                    actionBody={`Tem certeza que deseja excluir o registro: ${selectedRecord?.titulo}?`}
                                    handleConfirm={() => handleDelete(selectedRecord!)}
                                    handleCancel={() => setIsDelete(false)}
                                />
                            </section>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};
