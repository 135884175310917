import AppRoutes from './routes';
import { AuthProvider } from './contexts/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
    return (
        <AuthProvider>
            <AppRoutes />
            <ToastContainer
                theme='colored'
                // position="top-center"
                // autoClose={4000}
            />
        </AuthProvider>
    );
}

export default App;
