import { AvatarUpdate } from '../../../../components/avatarUpdate';
import { UserDataUpdateForm } from '../userDataUpdateFrom';
import { ChangePasswordForm } from '../changePasswordForm';
import { AppNavBar } from '../../../../components/navBar';
import { TabPanel } from '../../../../components/tab';
import { useState } from 'react';
import { Button, Col } from "reactstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/auth';
import { toast } from 'react-toastify';

export const User = () => {
    const [photo, setPhoto] = useState<FormData>();
    const [tabValue, setTabValue] = useState(0);
    const { Logout } = useAuth();

    const navigate = useNavigate();


    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleLogout = () => {
        Logout();
        navigate('/');
    };

    return (
        <div className="container min-zeebras">
            <AppNavBar />

            <div className="content p-4" style={{marginLeft: '80px'}}>
                <div className="d-flex gap-5">
                    <div className="profile">
                        <AvatarUpdate
                            onFileSelectSuccess={(file) => setPhoto(file)}
                            onFileSelectError={({ error }) =>  toast.error(error)}
                        />

                        {/* <div className="progressCard">
                            <ProgressCard />
                        </div> */}
                    </div>

                    <div className="d-flex flex-fill">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <h3 className="f36 bold mb-4">Editar Perfil</h3>

                            <Box className="tabs-codee" sx={{ borderColor: "divider" }}>
                                <Tabs value={tabValue} onChange={handleTabChange}>
                                    <Tab label="Dados Pessoais" {...a11yProps(0)} />
                                    <Tab label="Segurança" {...a11yProps(1)} />
                                    {/* <Tab label="Avaliação" {...a11yProps(2)} /> */}
                                    <Tab label="Sair" {...a11yProps(2)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={tabValue} index={0}>
                                <UserDataUpdateForm photo={photo!} />
                            </TabPanel>

                            <TabPanel value={tabValue} index={1}>
                                <ChangePasswordForm />
                            </TabPanel>

                            {/* <TabPanel value={tabValue} index={2}>
                                <Accordion />
                            </TabPanel> */}

                            <TabPanel value={tabValue} index={2}>
                                <Col md={8}>
                                    <div className="d-flex flex-column align-items-center text-center pt-4">
                                        <p className="f18 light text-uppercase pb-2">Atenção!</p>
                                        <p className="f28 bold text-uppercase pb-2">Deseja realizar o logout?</p>
                                        <p className="f18 light pb-4">
                                            Mas lembre-se: Você pode realizar o login novamente a qualquer momento na página inicial.
                                        </p>
                                        <Button onClick={handleLogout}>Sair</Button>
                                    </div>
                                </Col>

                            </TabPanel>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};
