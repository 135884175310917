import React from 'react';
import Logo from '../assets/icons/logo-zeebras.png'


const Loading: React.FC = () => {
    return (
        <div className='fallback-spinner app-loader'>
          <img className='fallback-logo' src={Logo} alt='logo' />
          <div className='loading'>
            <div className='effect-1 effects'></div>
            <div className='effect-2 effects'></div>
            <div className='effect-3 effects'></div>
          </div>
        </div>
    )
  }

  export default Loading;
