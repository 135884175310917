import AvatarImg from '../../assets/images/default-avatar.png';
import { useAuth } from '../../contexts/auth';
import formatFullName from '../../utils';
import './styles.css'

export const Avatar = () => {
    const { patient } = useAuth();

    const imagem = patient?.foto;

    return (
        <div className="d-flex align-items-center profile">
            <div className="d-flex flex-column name-level">
                <h3>{`${formatFullName(patient?.nome!)} ${patient?.sobrenome ?? ''}`}</h3>
                <p>Paciente</p>
            </div>

            <div className="avatar-container">
                <img src={imagem || AvatarImg} alt="Nome do Cliente" />
            </div>
        </div>
    );
};
