import * as React from 'react';

const LetterIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 2.5H13C13.6875 2.5 14.25 3.0625 14.25 3.75V11.25C14.25 11.9375 13.6875 12.5 13 12.5H3C2.3125 12.5 1.75 11.9375 1.75 11.25V3.75C1.75 3.0625 2.3125 2.5 3 2.5Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14.25 3.75L8 8.125L1.75 3.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default LetterIcon;
